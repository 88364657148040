import React from "react";
import { Link } from "react-scroll";

export default function Header() {
  return (
    <header>
      <nav className="flex justify-between items-center py-4 px-8 bg-dark">
        <div className="flex items-center justify-between w-full text-white">
          <a href="/" className="text-2xl font-bold">
            Best Casino Hotels
          </a>
          <ul className="flex ml-10 space-x-4 text-sm font-bold">
            <li>
              <a href="/">Homepage</a>
            </li>
            <li>
              <a href="/hotels">Best Casino Hotels</a>
            </li>
            <li>
            <Link
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70} // Adjust this value if needed to align with your design
                duration={500}
                className="bg-blue-600 p-2 rounded text-white cursor-pointer"
              >
                Get in Touch With Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
