import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import {Button} from '@headlessui/react';
import Contacts from './components/Contacts';

const HomePage = () => {
  return (
    <>
    <Header/>
    <div className="container mx-auto my-20 px-4">
      <header className="text-center my-20">
        <h1 className="text-4xl font-bold">Best Casino Hotels to Visit in Australia</h1>
        <p className="mt-4 text-lg">
          Discover the top casino hotels offering luxury, entertainment, and excitement across Australia.
        </p>
      </header>

      <section className="my-8">
        {infoBlocks.map((block, index) => (
          <div key={index} className="flex flex-col lg:flex-row items-center my-20">
            {index % 2 === 0 ? (
              <>
                <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                <div className="p-6 lg:w-1/2">
                  <h3 className="text-2xl font-semibold mb-4">{block.title}</h3>
                  <p className="text-gray-600">{block.description}</p>
                </div>
              </>
            ) : (
              <>
                <div className="p-6 lg:w-1/2">
                  <h3 className="text-2xl font-semibold mb-4">{block.title}</h3>
                  <p className="text-gray-600">{block.description}</p>
                </div>
                <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
              </>
            )}
          </div>
        ))}
      </section>

      <section className="my-8">
        <h2 className="text-3xl font-semibold mb-4">Are you in a hurry?</h2>
        <p className="mb-4">Here is a quick list of hotels in Sydney, selected based on their rating:</p>
        <table className="w-full text-left table-auto border-collapse bg-slate-50 rounded my-10">
          <thead>
            <tr>
              <th className="px-4 py-2">Name of Hotel</th>
              <th className="px-4 py-2">Accommodation Rating</th>
              <th className="px-4 py-2">Price, $</th>
              <th className="px-4 py-2">Availability</th>
            </tr>
          </thead>
          <tbody>
            {casinoHotels.map((hotel, index) => (
              <tr key={index} className="border-t">
                <td className="px-4 py-2">{hotel.name}</td>
                <td className="px-4 py-2">{hotel.rating}</td>
                <td className="px-4 py-2">{hotel.price}</td>
                <td className="px-4 py-2">
                  <a href={hotel.link} className="text-blue-500 hover:underline">Check Availability</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button as="a" href="/hotels" className="font-bold rounded bg-blue-600 py-2 px-4 text-sm text-white data-[hover]:bg-sky-500 data-[hover]:data-[active]:bg-sky-700 transition-all ease-in-out duration-150">
      See More
    </Button>
      </section>

     
    </div>
    <Contacts/>
    <Footer/>
    </>
  );
};

const infoBlocks = [
  {
    title: 'About Australia',
    description: 'Australia, a land of stunning landscapes, diverse wildlife, and vibrant cities, offers a unique blend of natural beauty and modern attractions. From the Great Barrier Reef to the Outback, there is no shortage of natural wonders to explore. The country is known for its laid-back lifestyle, friendly locals, and rich cultural heritage. Whether you are looking to relax on a beach, explore vibrant cities, or experience the thrill of the outback, Australia has something for everyone. The nation’s diverse climate ranges from tropical in the north to temperate in the south, ensuring a variety of experiences throughout the year.',
    image: '/assets/img-4.webp'
  },
  {
    title: 'Major Cities',
    description: 'Explore the bustling cities of Sydney, Melbourne, Brisbane, and Perth, each offering its own unique charm and attractions. Sydney, known for its iconic Opera House and Harbour Bridge, boasts a vibrant arts scene and beautiful beaches. Melbourne, often dubbed the cultural capital of Australia, is famous for its coffee culture, street art, and sporting events. Brisbane offers a laid-back lifestyle with a strong connection to nature, featuring parks and riverside attractions. Perth, the most isolated major city in the world, is known for its stunning coastline and rich history. Each city provides a different flavor of urban experience, making Australia a diverse travel destination.',
    image: '/assets/img-1.webp'
  },
  {
    title: 'Luxury Hotels',
    description: 'Australia is home to numerous luxury hotels that provide exceptional service, exquisite dining, and world-class amenities. These hotels are often located in prime locations, offering breathtaking views and easy access to major attractions. Guests can enjoy state-of-the-art facilities, including spas, fitness centers, and gourmet restaurants. The attention to detail and personalized service make for an unforgettable stay. Whether you are traveling for business or leisure, the luxury hotels in Australia ensure a comfortable and lavish experience.',
    image: '/assets/img-2.webp'
  },
  {
    title: 'Casino Hotels',
    description: 'Experience the thrill of gaming and the comfort of luxury at Australia’s top casino hotels, featuring state-of-the-art facilities and entertainment. These hotels combine world-class accommodations with extensive gaming options, including slot machines, table games, and poker rooms. Many casino hotels also offer live entertainment, gourmet dining, and exclusive VIP experiences. The vibrant atmosphere and top-notch service ensure an exciting and enjoyable stay. Whether you are a seasoned gambler or a casual visitor, the casino hotels in Australia offer something for everyone.',
    image: '/assets/img-3.avif'
  }
];

export default HomePage;
