const casinoHotels = [
    {
      name: 'Crown Melbourne',
      description: 'A world-class resort featuring luxury accommodation, fine dining, and a vast gaming floor.',
      image: '/assets/img-1.webp',
      link: 'https://www.crownmelbourne.com.au/',
      rating: '9.2 / 10',
      price: 'From $250 per night'
    },
    {
      name: 'The Star Sydney',
      description: 'Experience the ultimate in entertainment with top-tier amenities and stunning views of Sydney.',
      image: '/assets/img-2.webp',
      link: 'https://www.star.com.au/sydney/',
      rating: '8.9 / 10',
      price: 'From $220 per night'
    },
    {
      name: 'Jupiters Hotel & Casino',
      description: 'A premier destination on the Gold Coast offering a perfect blend of relaxation and excitement.',
      image:  '/assets/img-4.webp',
      link: 'https://www.star.com.au/goldcoast/',
      rating: '8.7 / 10',
      price: 'From $200 per night'
    },
    {
      name: 'Crown Perth',
      description: 'A luxurious escape in Perth with top-notch casino facilities, fine dining, and spectacular views.',
      image:  '/assets/img-3.avif',
      link: 'https://www.crownperth.com.au/',
      rating: '9.0 / 10',
      price: 'From $230 per night'
    },
    {
      name: 'The Star Gold Coast',
      description: 'Enjoy a world of excitement and luxury at The Star Gold Coast, featuring an extensive gaming floor and upscale accommodations.',
      image: '/assets/img-5.jpg',
      link: 'https://www.star.com.au/goldcoast/',
      rating: '8.8 / 10',
      price: 'From $210 per night'
    },
    {
      name: 'SkyCity Adelaide',
      description: 'Located in a stunning heritage building, SkyCity Adelaide offers a sophisticated gaming experience with luxurious accommodations and dining options.',
      image:  '/assets/img-6.jpg',
      link: 'https://www.skycityadelaide.com.au/',
      rating: '8.5 / 10',
      price: 'From $180 per night'
    }
  ];
  
  export default casinoHotels;
  