import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import {Button} from '@headlessui/react';
import Contacts from './components/Contacts';

const HomePage = () => {
  return (
    <>
    <Header/>
    <div className="container mx-auto p-4">
      <header className="text-center my-8">
        <h1 className="text-4xl font-bold">Best Casino Hotels to Visit in Australia</h1>
        <p className="mt-4 text-lg">Discover the top casino hotels offering luxury, entertainment, and excitement across Australia.</p>
      </header>
      
      <section className="my-8">
        <h2 className="text-3xl font-semibold mb-4">Top Casino Hotels</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {casinoHotels.map((hotel, index) => (
            <div key={index} className="border rounded-lg p-4 shadow-md">
              <img src={hotel.image} alt={hotel.name} className="w-full h-48 object-cover rounded-t-lg" />
              <h3 className="text-2xl font-semibold mt-4">{hotel.name}</h3>
              <p className="text-gray-600 mt-2">{hotel.description}</p>
              <a href={hotel.link} className="text-blue-500 hover:underline mt-4 block">Learn more</a>
            </div>
          ))}
        </div>
      </section>
      
    </div>
    <Contacts/>
    <Footer/>
    </>
  );
};

export default HomePage;
